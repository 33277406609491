import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import NewsCard from "../NewsCard/NewsCard";
import '../NewsTab/NewsTab.scss';
import CustomSliderNews from "../CustomSlider/CustomSliderNews";
import ScrollAnimation from "react-animate-on-scroll";
const { CTALink } = require("@starberry/gatsby-theme-utils/Modules/CTALinkModule")
const {
    ContentModule,
  } = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
const ValNewsSlider = (props) => {
    const sortedNews=props.data?.sort((a,b)=>{
        return new Date(b.node?.date) - new Date(a.node?.date)
    })

    const valSlider=sortedNews?.length>0?sortedNews?.slice(0,4):""
    return (
        <div className="val-news-slider-section news-tab-wrapper ">
            <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={100} offset={100}>
            <Container>
                <Row>
                    <Col>
                    <h3>{props?.title}</h3>
                    <div className="d-flex align-items-start justify-content-center">
                    {props.content && (
                      <ContentModule Content={props.content?.data?.content} /> 
                    )}
                    <CTALink class="button inline-btn" link={props.add_cta_item?.link} title={props.add_cta_item?.title} target_window={props.add_cta_item} />
                    </div>
                        <CustomSliderNews className={"news-card-wrapper-main-space"}>
                            {valSlider?.map(({ node }, index) => {
                                return (
                                    <NewsCard data={node} />
                                )
                            }
                            )}
                            </CustomSliderNews>
                    </Col>
                </Row>
            </Container>
            </ScrollAnimation>
        </div>
    )
}

export default ValNewsSlider