import loadable from "@loadable/component"
import { Link, graphql } from "gatsby"
import React from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Col, Container, Row } from "react-bootstrap"
import { PageLinks } from "../common/site/page-static-links"
import AccordianValuation from "../components/AccordianValuation/AccordianValuation"
import PageWithIcon from "../components/PageWithIcon/PageWithIcon"
import TwoColBlock from "../components/TwoColBlock/TwoColBlock"
import ValNewsSlider from "../components/ValNewsSlider/ValNewsSlider"
import Layout from "../components/layout"
import SEO from "../components/seo"
import reviewimg from "../images/google_review-w.svg"
const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")
const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")
// import ValuationImg_1 from "../images/valuation_img_1.png";
// import ValuationImg_2 from "../images/valuation_img_2.png";

const ValuationCard = loadable(() =>
  import("../components/ValuationCard/ValuationCard")
)

const ValuationLanding = ({ data }, props) => {
  const PageData = data?.strapiPage
  const blogs = data?.allStrapiBlog?.edges
  // console.log(PageData)

  let popUpData = ""

  if (PageData?.add_page_modules?.length > 0) {
    const results = PageData?.add_page_modules?.filter(
      item => item.__typename === "STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT"
    )
    if (results?.length > 0) {
      popUpData = results[0]
    }
  }

  return (
    <Layout
      popUpData={popUpData}
      dark={true}
      popularSearch={PageData?.select_popular_search?.title}
    >
      <div className="layout-padding-top"></div>
      {PageData?.add_page_modules?.length > 0 &&
        PageData.add_page_modules?.map((module, index) => {
          var imagename = "page.valuation_module.image"

          let processedImages = JSON.stringify({})
          if (PageData?.imagetransforms) {
            processedImages =
              PageData?.imagetransforms?.valuation_module_Transforms
          }

          return (
            <>
              {module.strapi_component === "page-modules.valuation-module" && (
                <div className="valuation-wrapper">
                  <ImageModule
                    ImageSrc={module?.image}
                    altText={`${
                      module?.image?.alternativeText
                        ? module?.image?.alternativeText
                        : module.title
                        ? module.title
                        : module.title
                    } banner`}
                    imagetransforms={processedImages}
                    renderer="bgImg"
                    imagename={imagename}
                    strapi_id={PageData?.strapi_id}
                    classNames="valuation-banner"
                  />
                  <ScrollAnimation
                    animateIn="animate__slideInUp"
                    animateOnce
                    delay={100}
                    offset={100}
                  >
                    <Container>
                      <Row className="d-flex justify-content-center">
                        <Col xl={8}>
                          <h1>{module.title}</h1>
                          {module.content && (
                            <ContentModule
                              Content={module.content?.data?.content}
                            />
                          )}
                          <div className="valuation-card-wrapper-main">
                            {module.add_details &&
                              module.add_details.map((item, index) => {
                                return (
                                  // <ScrollAnimation animateIn="animate__slideInUp" delay={index * 100} animateOnce offset={50}>
                                  <ValuationCard
                                    index={index}
                                    {...item}
                                    id={PageData.strapi_id}
                                    imagetransforms={
                                      PageData?.imagetransforms
                                        ?.tile_section_image_Transforms
                                    }
                                    // img={ValuationImg_1}
                                    text={item.title}
                                  />
                                  // </ScrollAnimation>
                                )
                              })}
                          </div>
                          <div className="review-bk">
                            <div className="review-img">
                              <Link to={`/${PageLinks?.reviews}/`}>
                                <img src={reviewimg} alt="reviews icon" />
                              </Link>
                            </div>
                            {/* <div className="review-txt">
                              <b>4.7/5</b> from over{" "}
                              <Link to="">700+ reviews</Link>
                            </div> */}
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </ScrollAnimation>
                </div>
              )}
              {module?.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_ACCORDION" && (
                <AccordianValuation
                  add_toggle={module?.add}
                  title={module?.title}
                  content={module?.content}
                  valuation={true}
                />
              )}
              {module?.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT" && (
                <TwoColBlock
                  id={PageData.strapi_id}
                  imagetransforms={PageData.imagetransforms}
                  {...module}
                  valuation
                />
              )}
              {module?.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES" &&
                module?.select_module === "blog" && (
                  <ValNewsSlider {...module} data={blogs} />
                )}
              {module?.__typename ===
                "STRAPI__COMPONENT_PAGE_MODULES_ICON_CONTENT" &&
                (module?.layout === "page" ? (
                  <PageWithIcon
                    id={PageData.strapi_id}
                    imagetransforms={
                      PageData.imagetransforms?.icon_section_image_Transforms
                    }
                    {...module}
                  />
                ) : null)}
            </>
          )
        })}
    </Layout>
  )
}

export const Head = ({ data }) => {
  const PageData = data?.strapiPage
  return (
    <SEO
      title={
        PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title
      }
      description={
        PageData?.seo?.metaDescription
          ? PageData?.seo?.metaDescription
          : PageData?.title
      }
      imageUrl="https://ggfx-handh3.s3.eu-west-2.amazonaws.com/i.prod/hh_logo_on_green_background_93feeb7b8b.png"

    />
  )
}

export default ValuationLanding

export const query = graphql`
  query ($page_id: String) {
    strapiPage(id: { eq: $page_id }) {
      ...PageFragment
      add_page_modules {
        ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
          ...PlainContentFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_ICON_CONTENT {
          __typename
          ...IconFragment
        }

        ... on STRAPI__COMPONENT_COMPONENTS_POPUP_CONTENT {
          __typename
          ...GlobalPopUpFragment
        }

        ... on STRAPI__COMPONENT_PAGE_MODULES_VALUATION_MODULE {
          ...ValuationModuleFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_IMAGE_CONTENT {
          __typename
          ...ImageContentFragment
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_CUSTOM_MODULES {
          __typename
          select_module
          title
          content {
            data {
              content
            }
          }
          add_cta_item {
            link {
              slug
              strapi_parent {
                slug
              }
            }
            title
            content {
              data {
                content
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_PAGE_MODULES_ACCORDION {
          __typename
          id
          add {
            content {
              data {
                content
              }
            }
            title
          }
          title
          content {
            data {
              content
            }
          }
        }
      }
    }

    allStrapiBlog(
      filter: { publish: { eq: true } }
      sort: { fields: date, order: ASC }
    ) {
      edges {
        node {
          title
          slug
          video_url
          read_time
          date(formatString: "DD MMM, yy")
          strapi_id
          tile_image {
            url
          }
          imagetransforms {
            tile_image_Transforms
          }
        }
      }
    }
  }
`
